import { createStore } from "vuex";

export default createStore({
  state: {
    logged: undefined,
    user: undefined,
    token: undefined,
  },
  mutations: {
    login(state, data) {
      state.logged = true;
      state.token = data.token;
    },
  },
  actions: {},
  modules: {},
});
