<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Add block</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="$emit('update:show', false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              />
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <div class="block-list">
            <div class="block" v-for="block in blocks" :key="block.id">
              <input
                type="radio"
                name="block"
                :id="'block-' + block.id"
                :value="block.id"
                v-model="selectedBlock"
              />
              <label :for="'block-' + block.id">
                <div class="block-icon" v-html="block.icon"></div>
                <div class="block-name">{{ block.name }}</div>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer custom-input">
          <button
            class="btn btn-primary"
            :disabled="selectedBlock === undefined"
            v-on:click="addBlock"
          >
            Add block
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blocks from "../../../public/templates/vivienVance/blocks";

const bootstrap = require("bootstrap");

export default {
  name: "AddBlock",
  props: {
    show: Boolean,
  },
  emits: ["update:show", "addBlock"],
  data() {
    return {
      myModal: undefined,
      selectedBlock: undefined,
      blocks: blocks,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    addBlock() {
      this.$emit("update:show", false);
      this.$emit("addBlock", this.blocks.filter((e) => e.id === this.selectedBlock)[0]);
    },
  },
};
</script>
