<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content" v-if="show">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Edit menu item</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="$emit('update:show', false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              />
            </svg>
          </button>
        </div>
        <div class="modal-body" v-if="dataModal">
          <div class="row mb-1" v-for="lang in languages" :key="lang.code">
            <div class="col-5">
              <label :for="'name-' + lang.code" class="col-form-label">
                {{ lang.name }} name
              </label>
            </div>
              <div class="col-7">
                <input
                  type="text"
                  class="form-control"
                  :id="'name-' + lang.code"
                  v-model="dataModal.translations[lang.code]"
                />
              </div>
          </div>
        </div>
        <div class="modal-footer custom-input">
          <button class="btn btn-primary" v-on:click="save">Save</button>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "EditMenuItem",
  props: {
    show: Boolean,
    languages: Array,
    data: Object,
    i: Number,
  },
  emits: ["update:show", "update:values"],
  data() {
    return {
      dataModal: {},
      myModal: undefined,
      language: undefined,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    languages() {
      if (this.language === undefined && this.languages.length > 0) {
        this.language = this.languages[0].code;
      }
    },
    data: {
      deep: true,
      handler() {
        this.dataModal = Object.assign({}, this.data);
      },
    },
    show() {
      if (this.show) {
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    save() {
      this.$emit("update:values", this.valuesData);
      this.$emit("save");
      this.$emit("update:show", false);
    },
  },
};
</script>
