<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Add new link</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="$emit('update:show', false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              />
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <label>Name</label>
            <input
                type="text"
                class="form-control"
                placeholder="name"
                v-model="name"
            />
          </div>
          <div class="mt-3">
            <label>Link</label>
            <input
              type="text"
              class="form-control"
              placeholder="https://page.com/test"
              v-model="link"
            />
          </div>
        </div>
        <div class="modal-footer custom-input">
          <button
            class="btn btn-primary"
            :disabled="link.length < 1 && name.length < 1"
            v-on:click="addLink"
          >
            Add link
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "AddNewLink",
  props: {
    show: Boolean,
  },
  emits: ["update:show", "addLink"],
  data() {
    return {
      myModal: undefined,
      link: "",
      name: "",
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    addLink() {
      this.$emit("update:show", false);
      this.$emit("addLink", {link: this.link, name: this.name});
    },
  },
};
</script>
