<template>
  <header-block name="Home"></header-block>
  <aside-block active="home" />
  <div class="container mt-3">
    <h1>Home</h1>
    <div class="row">
      <div class="col-4 p-2">
        <div class="white-box">
          <h2>Manage pages</h2>
          <router-link to="/editor" class="btn btn-primary">
            Start managing pages
          </router-link>
        </div>
      </div>
      <div class="col-4 p-2">
        <div class="white-box">
          <h2>Manage menus</h2>
          <router-link to="/menu" class="btn btn-primary">
            Start managing menus
          </router-link>
        </div>
      </div>
      <div class="col-4 p-2">
        <div class="white-box">
          <h2>Manage translations</h2>
          <router-link to="/translate" class="btn btn-primary">
            Start managing translations
          </router-link>
        </div>
      </div>
      <div class="col-6 p-2">
        <div class="white-box">
          <h1>Let's get started</h1>
          <p>Minions ipsum gelatooo uuuhhh para tú bappleees para tú tank yuuu! Gelatooo po kass. Bappleees poopayee tulaliloo pepete belloo! Wiiiii. Baboiii hana dul sae bappleees pepete hana dul sae po kass po kass baboiii. Belloo! hahaha baboiii poopayee hahaha belloo! La bodaaa bee do bee do bee do chasy. Pepete poopayee tank yuuu! Butt la bodaaa wiiiii aaaaaah ti aamoo! Poulet tikka masala. Tatata bala tu daa ti aamoo! Poulet tikka masala poopayee wiiiii bappleees hana dul sae ti aamoo! Jeje belloo!</p>
        </div>
      </div>
      <div class="col-6 p-2">
        <div class="white-box">
          <h1>placeholder</h1>
          <p>Minions ipsum gelatooo uuuhhh para tú bappleees para tú tank yuuu! Gelatooo po kass. Bappleees poopayee tulaliloo pepete belloo! Wiiiii. Baboiii hana dul sae bappleees pepete hana dul sae po kass po kass baboiii. Belloo! hahaha baboiii poopayee hahaha belloo! La bodaaa bee do bee do bee do chasy. Pepete poopayee tank yuuu! Butt la bodaaa wiiiii aaaaaah ti aamoo! Poulet tikka masala. Tatata bala tu daa ti aamoo! Poulet tikka masala poopayee wiiiii bappleees hana dul sae ti aamoo! Jeje belloo!</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import HeaderBlock from "../components/header";
import AsideBlock from "../components/asideBlock";
export default {
  name: "HomePage",
  components: {AsideBlock, HeaderBlock},
}
</script>
