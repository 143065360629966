import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomePage.vue";
import BlockEditor from "../views/BlockEditor";
import TranslatorPage from "../views/TranslatorPage";
import MenuEditor from "../views/MenuEditor";
import PageList from "../views/PageList";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/editor",
    name: "Page editor",
    component: PageList,
  },
  {
    path: "/editor/:id",
    name: "Block editor",
    component: BlockEditor,
  },
  {
    path: "/translator",
    name: "Translator",
    component: TranslatorPage,
  },
  {
    path: "/menu",
    name: "menu editor",
    component: MenuEditor,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/errors/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
