<template>
  <header>
    <div class="container-fluid row align-items-center">
      <div class="col-4">
        <div class="openable-box-container">
          <button class="openable-box-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="currentColor"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
              />
            </svg>
            {{name}}
          </button>
          <div class="openable-box">{{page}}</div>
        </div>
      </div>
      <slot></slot>
    </div>
  </header>
</template>
<script>

export default {
  name: "HeaderBlock",
  props: {
    name: String,
    page: String,
  },
};
</script>
