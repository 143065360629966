<template>
  <header-block name="Menus">
    <nav class="col-6">
      <button
        v-for="language in languages"
        :key="language.code"
        class="btn"
        v-on:click="activeLanguage = language"
        :class="
          activeLanguage?.code === language.code ? 'btn-primary' : 'btn-link'
        "
      >
        {{ language.name }}
      </button>
    </nav>
  </header-block>
  <aside-block active="menu" />
  <div class="container">
    <div class="row white-box mt-3">
      <nav class="col-3 col-lg-2 d-flex flex-column">
        <button
          v-for="page in menus"
          :key="page.id"
          v-on:click="activeMenu = page"
          class="btn text-start"
          :class="activeMenu?.id === page.id ? 'btn-primary' : 'btn-link'"
        >
          {{ page.name }}
        </button>
      </nav>
      <div class="col-9 col-lg-10" v-if="activeMenu">
        <h1>Menu editor</h1>
        <div class="row">
          <div id="selectedMenus" class="menueditor col-7">
            <h2>Active items</h2>
            <div class="item-list h-100">
              <draggable
                :list="activeMenu.menu_items"
                group="menus"
                filter=".filtered"
                animation="300"
                @change="save"
              >
                <template #item="{ element }">
                  <div class="item list-group-item">
                    <div class="row">
                      <div class="col">
                        {{ element.translations[activeLanguage.code] }}
                      </div>
                      <div class="col-auto menu-url" v-if="element.target_url">
                        {{ element.target_url }}
                      </div>
                      <div
                        class="col-auto menu-url"
                        v-if="element.target_category_id"
                      >
                        /category/{{ element.target_category_id }}-{{
                          element.slug
                        }}
                      </div>
                      <div
                        class="col-auto menu-url"
                        v-if="element.target_page_id"
                      >
                        /{{ element.target_page_id }}-{{ element.slug }}
                      </div>
                      <div class="col-auto">
                        <button
                          class="btn btn-round"
                          v-on:click="editItem(element)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                            />
                          </svg>
                        </button>
                        <button
                          class="btn btn-round"
                          v-on:click="deleteItem(element)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
          <div id="availableMenus" class="menueditor col-5">
            <h2>Available items</h2>
            <h3>Categories</h3>
            <div class="item-list">
              <draggable
                :list="possibleCategoryItems"
                :group="{ name: 'menus', pull: 'clone', put: false }"
                filter=".filtered"
                animation="300"
              >
                <template #item="{ element }">
                  <div class="item list-group-item">
                    <div class="row">
                      <div class="col">
                        {{ element.translations[activeLanguage.code] }}
                      </div>
                      <div class="menu-url col-auto">
                        /category/{{ element.target_category_id }}-{{
                          element.slug
                        }}
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
            <h3>Pages</h3>
            <div class="item-list">
              <draggable
                :list="possiblePageItems"
                :group="{ name: 'menus', pull: 'clone', put: false }"
                filter=".filtered"
                animation="300"
              >
                <template #item="{ element }">
                  <div class="item list-group-item">
                    <div class="row">
                      <div class="col">
                        {{ element.translations[activeLanguage.code] }}
                      </div>
                      <div class="menu-url col-auto">
                        /{{ element.target_category_id }}-{{ element.slug }}
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
            <div class="row">
              <div class="col">
                <h3>Custom URL</h3>
              </div>
              <div class="col-auto">
                <button class="btn btn-round" v-on:click="addLinkShow = true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="item-list">
              <draggable
                :list="possibleLinkItems"
                :group="{ name: 'menus', pull: 'clone', put: false }"
                filter=".filtered"
                animation="300"
              >
                <template #item="{ element }">
                  <div class="item list-group-item">
                    <div class="row">
                      <div class="col">
                        {{ element.translations[activeLanguage.code] }}
                      </div>
                      <div class="col-auto menu-url">
                        {{ element.target_url }}
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddNewLink v-model:show="addLinkShow" v-on:addLink="addLink" />
    <EditMenuItem
      v-model:show="editItemShow"
      v-model:data="selectedItem"
      v-model:languages="languages"
      v-on:save="saveItem"
    />
  </div>
</template>
<script>
import HeaderBlock from "../components/header";
import http from "../modules/http";
import draggable from "vuedraggable";
import AddNewLink from "../components/modals/AddNewLink";
import AsideBlock from "../components/asideBlock";
import EditMenuItem from "../components/modals/EditMenuItem";

export default {
  name: "TranslatorPage",
  components: { EditMenuItem, AsideBlock, AddNewLink, HeaderBlock, draggable },
  data() {
    return {
      menus: [],
      languages: [],
      activeLanguage: {},
      possibleCategoryItems: [],
      possibleLinkItems: [],
      possiblePageItems: [],
      activeMenu: {},
      words: [],
      selectedItem: {},
      addLinkShow: false,
      editItemShow: false,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    activePage() {
      if (this.activeLanguage?.code !== undefined) {
        this.loadWords();
      }
    },
    activeLanguage() {
      if (this.activeMenu?.id !== undefined) {
        this.loadWords();
      }
    },
  },
  methods: {
    load() {
      let loaded = 0;
      http.fetch("/page").then((data) => {
        for (const item of data.data) {
          item.target_page_id = item.id;
          delete item.id;
          item.translations = {};
          item.translations[this.activeLanguage.code] = item.name;
          this.possiblePageItems.push(item);
        }
        loaded++;
        if (loaded === 3) {
          this.loadMenu();
        }
      });
      http.fetch("/menu").then((data) => {
        this.menus = data.data;
        this.activeMenu = this.menus[0];
        loaded++;
        if (loaded === 3) {
          this.loadMenu();
        }
      });
      http.fetch("/languages").then((data) => {
        this.languages = data;
        this.activeLanguage = this.languages[0];

        http
          .fetch("/products/categories/products/" + this.activeLanguage.code)
          .then((data) => {
            this.addCategories(data["data"]);
            loaded++;
            if (loaded === 3) {
              this.loadMenu();
            }
          });
      });
    },
    loadMenu() {
      for (const menu of this.menus) {
        for (const item of menu.menu_items) {
          if (
            item.target_category_id !== null &&
            item.target_category_id !== undefined
          ) {
            let e = this.possibleCategoryItems.find(
              (e) => e.target_category_id === item.target_category_id
            );
            if (e !== undefined) {
              item.slug = e.slug;
            }
          }
          if (
            item.target_page_id !== null &&
            item.target_page_id !== undefined
          ) {
            let e = this.possiblePageItems.find(
              (e) => e.target_page_id === item.target_page_id
            );
            if (e !== undefined) {
              item.slug = e.slug;
            }
          }
          if (
            item.target_url !== null &&
            item.target_url !== undefined &&
            item.target_url.length > 0
          ) {
            let e = {
              translations: item.translations,
              target_url: item.target_url,
            };
            this.possibleLinkItems.push(e);
          }
        }
      }
    },
    addCategories(categories) {
      for (const item of categories) {
        item.target_category_id = item.id;
        delete item.id;
        item.translations = {};
        item.translations[this.activeLanguage.code] = item.name;
        this.possibleCategoryItems.push(item);
        if (item.categories !== undefined) {
          this.addCategories(item.categories);
        }
      }
    },
    loadWords() {
      this.possibleCategoryItems = [];
      http
        .fetch("/products/categories/products/" + this.activeLanguage.code)
        .then((data) => {
          this.addCategories(data["data"]);
        });
    },
    addNew(newIndex, element) {
      let add = {
        order: newIndex,
      };
      add.translations = element.translations;
      if (element.target_category_id !== undefined) {
        add.target_category_id = element.target_category_id;
      }
      if (element.target_page_id !== undefined) {
        add.target_page_id = element.target_page_id;
      }
      if (element.target_url !== undefined) {
        add.target_url = element.target_url;
      }

      http.fetch(`/menus/${this.activeMenu.id}/menuitem`, add).then((data) => {
        console.log(data);
      });
    },
    editItem(element) {
      this.selectedItem = element;
      this.editItemShow = true;
    },
    saveItem() {
      http
        .fetch(
          `/menus/${this.activeMenu.id}/menuitem/${this.selectedItem.id}`,
          this.selectedItem,
          false,
          "PUT"
        )
        .then((data) => {
          console.log(data);
        });
    },
    deleteItem(element) {
      console.log(element);
      let i = this.activeMenu.menu_items.findIndex((e) => e.id === element.id);
      this.activeMenu.menu_items.splice(i, 1);
      http
        .fetch(
          `/menus/${this.activeMenu.id}/menuitem/${element.id}`,
          {},
          false,
          "DELETE"
        )
        .then((data) => {
          console.log(data);
        });
    },
    order() {
      let order = [];
      let i = 0;
      for (const item of this.activeMenu.menu_items) {
        order.push({
          id: item.id,
          position: i++,
        });
      }
      http
        .fetch(
          `/menus/${this.activeMenu.id}/menuitem/order`,
          { data: order },
          false,
          "PUT"
        )
        .then((data) => {
          console.log(data);
        });
    },
    save(event) {
      if (event.added !== undefined) {
        this.addNew(event.added.newIndex, event.added.element);
      }
      if (event.moved !== undefined) {
        this.order();
      }
    },
    addLink(link) {
      let item = { translations: {}, target_url: link.link };
      item.translations[this.activeLanguage.code] = link.name;
      this.possibleLinkItems.push(item);
    },
  },
};
</script>
