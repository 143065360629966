<template>
  <header-block name="translator">
    <nav class="col-6">
      <button
        v-for="language in languages"
        :key="language.code"
        class="btn"
        v-on:click="activeLanguage = language"
        :class="
          activeLanguage?.code === language.code ? 'btn-primary' : 'btn-link'
        "
      >
        {{ language.name }}
      </button>
    </nav>
    <div class="col-2 text-end">
      <nav>
        <button v-on:click="save">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M18 19h1V6.828L17.172 5H16v4H7V5H5v14h1v-7h12v7zM4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4 11v5h8v-5H8z"
            />
          </svg>
        </button>
      </nav>
    </div>
  </header-block>
  <aside-block active="translator" />
  <div class="container">
    <div class="white-box mt-3 row">
      <nav class="col-3 col-lg-2 d-flex flex-column">
        <button
          v-for="page in pages"
          :key="page.id"
          v-on:click="activePage = page"
          class="btn text-start"
          :class="activePage?.id === page.id ? 'btn-primary' : 'btn-link'"
        >
          {{ page.name }}
        </button>
      </nav>
      <div class="col-9 col-lg-10" v-if="activePage">
        <h1>Translator</h1>
        <h2>{{ activePage?.name }}</h2>
        <div class="form-group row my-2" v-for="word in words" :key="word.key">
          <label :for="'input' + word.key" class="col-sm-5 col-form-label" :title="word.key">
            {{ word.text_en ?? word.key }}
          </label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              :id="'input' + word.key"
              :placeholder="activeLanguage.name + ' word'"
              v-model="word.text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBlock from "../components/header";
import http from "../modules/http";
import AsideBlock from "../components/asideBlock";
export default {
  name: "TranslatorPage",
  components: {AsideBlock, HeaderBlock },
  data() {
    return {
      pages: [],
      languages: [],
      activeLanguage: {},
      activePage: {},
      words: [],
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    activePage() {
      if (this.activeLanguage?.code !== undefined) {
        this.loadWords();
      }
    },
    activeLanguage() {
      if (this.activePage?.id !== undefined) {
        this.loadWords();
      }
    },
  },
  methods: {
    load() {
      http.fetch("/translation/categories").then((data) => {
        this.pages = data.data;
        this.activePage = this.pages[0];
      });
      http.fetch("/languages").then((data) => {
        this.languages = data;
        this.activeLanguage = this.languages[0];
      });
    },
    loadWords() {
      http
        .fetch(
          `/translation/${this.activeLanguage.code}/${this.activePage.file}/en`
        )
        .then((data) => {
          this.words = data.data.translations;
        });
    },
    save() {
      http
          .fetch(
              `/translation/create`,{
                file_name: this.activePage.file,
                lang: this.activeLanguage.code,
                name: this.activePage.name,
                rows: this.words.filter((e) => e.text !== undefined)
              }
          )
          .then((data) => {
            console.log(data);
          });
    },
  },
};
</script>
