<template>
  <div
    ref="modal"
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header">
      <h5>Global template settings</h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        v-on:click="$emit('update:show', false)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
          />
        </svg>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-4" v-for="(val, key) in modelValueData" :key="key">
          <div class="input-group" v-if="val.type === 'color'">
            <label :title="key">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                v-if="key.indexOf('font') > -1 || key.indexOf('text') > -1"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M15.246 14H8.754l-1.6 4H5l6-15h2l6 15h-2.154l-1.6-4zm-.8-2L12 5.885 9.554 12h4.892zM3 20h18v2H3v-2z"
                />
              </svg>
              <svg
                v-else-if="key.indexOf('background') > -1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M19.228 18.732l1.768-1.768 1.767 1.768a2.5 2.5 0 1 1-3.535 0zM8.878 1.08l11.314 11.313a1 1 0 0 1 0 1.415l-8.485 8.485a1 1 0 0 1-1.414 0l-8.485-8.485a1 1 0 0 1 0-1.415l7.778-7.778-2.122-2.121L8.88 1.08zM11 6.03L3.929 13.1H18.07L11 6.03z"
                />
              </svg>
              <svg
                v-else-if="key.indexOf('hover') > -1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M15.388 13.498l2.552 7.014-4.698 1.71-2.553-7.014-3.899 2.445L8.41 1.633l11.537 11.232-4.558.633zm-.011 5.818l-2.715-7.46 2.96-.41-5.64-5.49-.79 7.83 2.53-1.587 2.715 7.46.94-.343z"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 2c5.522 0 10 3.978 10 8.889a5.558 5.558 0 0 1-5.556 5.555h-1.966c-.922 0-1.667.745-1.667 1.667 0 .422.167.811.422 1.1.267.3.434.689.434 1.122C13.667 21.256 12.9 22 12 22 6.478 22 2 17.522 2 12S6.478 2 12 2zm-1.189 16.111a3.664 3.664 0 0 1 3.667-3.667h1.966A3.558 3.558 0 0 0 20 10.89C20 7.139 16.468 4 12 4a8 8 0 0 0-.676 15.972 3.648 3.648 0 0 1-.513-1.86zM7.5 12a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm9 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM12 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                />
              </svg>
            </label>
            <input
              type="color"
              v-model="val.value"
              v-on:change="change(key, val.value)"
              v-on:input="change(key, val.value)"
            />
          </div>
          <div class="input-group" v-else-if="val.type === 'font-size'">
            <label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11.246 15H4.754l-2 5H.6L7 4h2l6.4 16h-2.154l-2-5zm-.8-2L8 6.885 5.554 13h4.892zM21 12.535V12h2v8h-2v-.535a4 4 0 1 1 0-6.93zM19 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                />
              </svg>
            </label>
            <input
              type="number"
              v-model="val.value"
              v-on:change="change(key, val.value + 'px')"
            />
          </div>
          <div class="input-group" v-else-if="val.type === 'line-height'">
            <label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 4h10v2H11V4zM6 7v4H4V7H1l4-4 4 4H6zm0 10h3l-4 4-4-4h3v-4h2v4zm5 1h10v2H11v-2zm-2-7h12v2H9v-2z"
                />
              </svg>
            </label>
            <input
              type="number"
              v-model="val.value"
              v-on:change="change(key, val.value)"
            />
          </div>
          <div class="input-group" v-else-if="val.type === 'font-family'">
            <label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M13 6v15h-2V6H5V4h14v2z" />
              </svg>
            </label>
            <select
              v-model="val.value"
              v-on:change="
                fontChange($event.target.value.length !== 0);
                change(key, val.value, true);
              "
              v-if="
                showSelect &&
                (val.value.length === 0 ||
                  fonts.filter((e) => e.family + ',' + e.category === val.value)
                    .length > 0)
              "
            >
              <option
                v-for="font in fonts"
                :value="font.family + ',' + font.category"
                :key="font.family"
              >
                {{ font.family }}
              </option>
              <option value="">Custom</option>
            </select>
            <input
              v-else
              type="text"
              v-model="val.value"
              v-on:input="fontChange($event.target.value.length === 0)"
              v-on:change="change(key, val.value, true)"
            />
          </div>
          <div class="input-group" v-else>
            <label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M8.686 4l2.607-2.607a1 1 0 0 1 1.414 0L15.314 4H19a1 1 0 0 1 1 1v3.686l2.607 2.607a1 1 0 0 1 0 1.414L20 15.314V19a1 1 0 0 1-1 1h-3.686l-2.607 2.607a1 1 0 0 1-1.414 0L8.686 20H5a1 1 0 0 1-1-1v-3.686l-2.607-2.607a1 1 0 0 1 0-1.414L4 8.686V5a1 1 0 0 1 1-1h3.686zM6 6v3.515L3.515 12 6 14.485V18h3.515L12 20.485 14.485 18H18v-3.515L20.485 12 18 9.515V6h-3.515L12 3.515 9.515 6H6zm6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                />
              </svg>
            </label>
            <input
              type="text"
              v-model="val.value"
              v-on:change="change(key, val.value)"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer custom-input">
        <button class="btn btn-primary" v-on:click="addBlock">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "GlobalSettings",
  props: {
    modelValue: Object,
    show: Boolean,
  },
  emits: ["update:modelValue", "update:show", "change", "importCss"],
  data() {
    return {
      showSelect: true,
      myModal: undefined,
      modelValueData: [],
      fonts: [],
    };
  },
  mounted() {
    this.loadFonts();
    this.modelValueData = this.modelValue;
    this.myModal = new bootstrap.Offcanvas(this.$refs.modal, {
      backdrop: false,
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.modelValueData = this.modelValue;
      },
    },
    show() {
      if (this.show) {
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    fontChange(change) {
      this.showSelect = change;
    },
    loadFonts() {
      fetch(
        "https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyB4_nf8d1XhddooWRAoqqspCevWBiaNBeU"
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.fonts = data.items;
        });
    },
    change(key, val, font = false) {
      this.$emit("change", key, val);

      if (font) {
        let fontVal = this.fonts.filter(
          (e) => e.family + "," + e.category === val
        );
        if (fontVal.length > 0) {
          fontVal = fontVal[0];
          this.$emit(
            "importCss",
            `https://fonts.googleapis.com/css2?family=${fontVal.family}:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap`
          );
        }
      }
    },
  },
};
</script>
