<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Add page</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="$emit('update:show', false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              />
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <label>Name</label>
            <input type="text" class="form-control" v-model="name" />
          </div>
          <div class="mt-3" v-if="selectedLanguage">
            <label>{{selectedLanguage.name}} title</label>
            <input
              type="text"
              class="form-control"
              v-model="title[selectedLanguage.code]"
            />
          </div>
          <div class="mt-3" v-if="selectedLanguage">
            <label>{{selectedLanguage.name}} description</label>
            <input
                type="text"
                class="form-control"
                v-model="description[selectedLanguage.code]"
            />
          </div>
          <div class="mt-3">
            <label>Slug</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="slug"
            />
          </div>
          <div class="mt-3">
            <label>Default language</label>
            <select class="form-control" v-model="language">
              <option
                v-for="lang in languages"
                :key="lang.code"
                :value="lang.code"
              >
                {{ lang.name }}
              </option>
            </select>
          </div>
          <div class="mt-3">
            <label>Webstore</label>
            <select class="form-control" v-model="webshop">
              <option :value="null">All webstore</option>
              <option
                v-for="webstore in webshops"
                :key="webstore.id"
                :value="webstore.id"
              >
                {{ webstore.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer custom-input">
          <div class="row w-100">
            <div class="col-3">
              <div class="input-group">
                <label>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                  >
                    <path
                        d="M18.5 10l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16.5 10h2zM10 2v2h6v2h-1.968a18.222 18.222 0 0 1-3.62 6.301 14.864 14.864 0 0 0 2.336 1.707l-.751 1.878A17.015 17.015 0 0 1 9 13.725a16.676 16.676 0 0 1-6.201 3.548l-.536-1.929a14.7 14.7 0 0 0 5.327-3.042A18.078 18.078 0 0 1 4.767 8h2.24A16.032 16.032 0 0 0 9 10.877a16.165 16.165 0 0 0 2.91-4.876L2 6V4h6V2h2zm7.5 10.885L16.253 16h2.492L17.5 12.885z"
                    />
                  </svg>
                </label>
                <select class="form-control" v-model="selectedLanguage">
                  <option
                      v-for="lang in languages"
                      :key="lang.code"
                      :value="lang"
                  >
                    {{ lang.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col"></div>
            <div class="col-auto">
              <button
                  v-if="page?.id"
                  class="btn btn-primary"
                  :disabled="slug.length < 1 && name.length < 1"
                  v-on:click="editPage"
              >
                Edit page
              </button>
              <button
                  v-else
                  class="btn btn-primary"
                  :disabled="slug.length < 1 && name.length < 1"
                  v-on:click="addPage"
              >
                Add new page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";

const bootstrap = require("bootstrap");

export default {
  name: "AddPage",
  props: {
    show: Boolean,
    page: Object,
    webshops: Array,
  },
  emits: ["update:show", "add", "edit"],
  data() {
    return {
      myModal: undefined,
      title: {},
      description: {},
      name: "",
      slug: "",
      selectedLanguage: "",
      language: "",
      languages: [],
      webshop: "",
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    page: {
      deep: true,
      handler() {
        if (this.page) {
          this.title = this.page.title;
          this.name = this.page.name;
          this.description = this.page.description;
          this.slug = this.page.slug;
          this.language = this.page.language_code;
          this.webshop = this.page.webshop_id;
        } else {
          this.title = {};
          this.description = {};
          this.name = "";
          this.slug = "";
          this.language = "";
          this.webshop = "";
        }
      },
    },
    show() {
      if (this.show) {
        this.myModal.show();

        if (this.languages.length === 0) {
          http.fetch("/languages").then((data) => {
            this.languages = data;
            this.selectedLanguage = data[0];
          });
        }
      } else {
        this.myModal.hide();
      }
    },
    name() {
      this.slug = this.name
        .replace(/[\W_]/g, "-")
        .toLowerCase()
        .replace(/--/g, "-");
    },
  },
  methods: {
    editPage() {
      this.$emit("update:show", false);
      this.$emit("edit", {
        id: this.page.id,
        title: this.title,
        description: this.description,
        name: this.name,
        slug: this.slug,
        language_code: this.language,
        webshop_id: this.webshop ?? "",
      });
    },
    addPage() {
      this.$emit("update:show", false);
      this.$emit("add", {
        title: this.title,
        description: this.description,
        name: this.name,
        slug: this.slug,
        language_code: this.language,
        webshop_id: this.webshop ?? "",
      });
    },
  },
};
</script>
