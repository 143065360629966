<template>
  <div v-if="!logged" class="loading">
    <div class="loading-ring"><div></div><div></div><div></div><div></div></div>
  </div>
  <router-view />
  <iframe
    style="display: none"
    ref="managerIframe"
    :src="managerUrl + '/loginEditor.html'"
    sandbox="allow-scripts allow-same-origin"
  ></iframe>
</template>
<script>
import store from "@/store";

export default {
  name: "AppStart",
  data() {
    return {
      logged: false,
    };
  },
  computed: {
    managerUrl() {
      return process.env.VUE_APP_MANAGER_URL;
    },
  },
  mounted() {
    const _this = this;
    window.addEventListener(
      "message",
      function (event) {
        const { action, token } = event.data;
        if (action === "setToken") {
          if (token === null || token?.length < 1) {
            window.location = _this.managerUrl;
          }
          store.commit("login", {
            token: token,
          });
          _this.logged = true;
        }
      },
      false
    );
    this.getToken();
    setTimeout(this.getToken, 1000);
  },
  methods: {
    getToken() {
      this.$refs.managerIframe.contentWindow.postMessage(
        {
          action: "getToken",
        },
        "*"
      );
    },
  },
};
</script>
